import React from "react";

const CheckRequiredAssetList = (props) => {
    return (
        <div>
            {props.RequiredDocuments === undefined || props.RequiredDocuments.length <= 0 ? null:
                <div className="evidence-section">
                   <div className="title">
                   <h5><strong>Required Documents</strong></h5>
                   </div>
                   <section className="evidence-wrap">
                      {props.RequiredDocuments}
                   </section>
                </div>}
                {props.RequiredCertificates === undefined || props.RequiredCertificates.length <= 0 ? null:
                <div className="evidence-section">
                   <div className="title">
                   <h5><strong>Required Certificates</strong></h5>
                   </div>
                   <section className="evidence-wrap">
                      {props.RequiredCertificates}
                   </section>
                </div>}
                {props.OptionalDocuments === undefined || props.OptionalDocuments.length <= 0 ? null :
                <div className="evidence-section">
                   <div className="title">
                   <h5><strong>Optional Documents & Certification</strong></h5>
                   </div>
                   <section className="evidence-wrap">
                      {props.OptionalDocuments}
                   </section>
            </div>}
        </div>
    );
}

export default CheckRequiredAssetList;