import React from "react";

const AboutYou = (props) => {
    return (
        <div className="form-group">
            <label htmlFor="aboutYou"><strong>About You:</strong></label>
            <textarea rows="5" value={props.about_me ? props.about_me : ""} className="form-control" onChange={props.handleChangeAboutYou}></textarea>
            {props.response_status === true ? <span className={props.statusmessagecolor}>{props.response_status_message}</span>:null}
            <br />
            {props.enable_save_button === true ? (<button type="button" className="btn btn-sm btn-warning d-inline-block" onClick={props.handleSubmitAboutYou}>Save</button>):null}
        </div>
    );
}

export default AboutYou;