import React, { Component } from "react";
import validator from "validator";
import HiupApi from "../../Sources/HiupApi";
import AtsiApplicationSent from "../ApplicationSent/AtsiApplicationSent";
import PreLoader from '../PreLoader/PreLoader';
import Footer from './Footer';
import JobInformation from './JobInformation';


export default class ApplicationForm extends Component {

   constructor(props) {
      super(props)
      this.state = {
         position_details: {},
         is_loading: true,
         is_published: true,
         can_apply_job: true,
         read_privacy_policy_checked: false,
         error_status: false,
         error_message: null,
         loader: false,
         checkbox: false,
         application_sent: false,
         enable_save_button: false,
         response_status_message: "",
         response_status: false,
         applicationStatus: "",
         statusmessagecolor: ''
      }
   }

   componentDidMount() {
      const self = this;

      let isValid = validator.isUUID(self.props.match.params.user_id)
      if (isValid === false) {
         this.setState({ is_published: false })
      } else {
         self.getJobSeekerDetails()
         Promise.all([self.getPositionDetails()])
            .then(result => {
               self.setState({ is_loading: false })
            }).catch(errorMessage => {
               this.setState({ is_published: false })
            })
      }

   }

   getPositionDetails = () => {

      const self = this;

      let api = new HiupApi();
      return api.getPosition(self.props.match.params.position_id)
         .then((position_details) => {
            if (position_details.job_id) {
               this.setState({ position_details: position_details })
            }
         }).catch((errorMessage) => {
            this.setState({ is_published: false })
         });
   }

   getJobSeekerDetails = () => {

      const self = this;

      const api = new HiupApi();
      api.getJobseekerInformation().then(function (jobseeker_profile) {
         self.setState({
            title: jobseeker_profile.title,
            given_names: jobseeker_profile.given_names,
            family_name: jobseeker_profile.family_name,
            email_address: jobseeker_profile.email_address,
            contact_number: jobseeker_profile.contact_number,
            about_me: jobseeker_profile.about_me,
         })
      }).catch((errorMessage) => {
         console.log("yyyyyyyyyyyyyyyyyyyy", errorMessage);
         this.setState({ is_published: false })
      });
   }

   onSelectValue = (event) => {
      this.setState({ location_value: event.target.value })
   }

   onTogglePrivacyPolicyButton = () => {

      this.setState({
         read_privacy_policy_checked: !this.state.read_privacy_policy_checked,
         checkbox: !this.state.checkbox
      })
   }

   createApplication = () => {

      const self = this;
      const api = new HiupApi();
      return api.createApplication(self.props.match.params.position_id,self.props.match.params.user_id).then(function (response) {
         if (response.data) {
            return response.data
         }
      }).catch(errorMessage => {

         self.setState({ loader: false })
         if (errorMessage.data) {
            self.setState({ error_status: true, error_message: errorMessage.data.user_message })
         } else {
            self.setState({ error_status: true, error_message: "something went wrong" })
         }
      })
   }

   onSubmitApplication = (event) => {

      const self = this;

      event.preventDefault();
      this.setState({ loader: true })
      self.createApplication().then(function (response) {
         console.log("responseresponseresponseresponseresponse",response);
         self.setState({ loader: false, application_sent: true, applicationStatus: response.Header ? response.Header.Status : '' })
      })
   }

   render() {

      const job_position_details = this.state.position_details;
      return (
         <div>

            {this.state.is_published === false ?
               (<div>
                  <h1 className="title loading-bottom">POSITION NOT PUBLISHED</h1>
               </div>) :
               this.state.is_loading === true ? (<PreLoader />) :
                  <div>
                     {this.state.application_sent === false ?
                        <div className="body-wrap-in">
                           <div className="row">
                              <div id="main" className="col-lg-9 col-md-12 col-sm-12 col-xs-12 padding-right60">
                                 <JobInformation job_position_details={job_position_details} />                                 
                              </div>
                           </div>
                           <Footer
                              can_apply_job={this.state.can_apply_job}
                              checkbox={this.state.checkbox}
                              onTogglePrivacyPolicyButton={this.onTogglePrivacyPolicyButton}
                              error_status={this.state.error_status}
                              error_message={this.state.error_message}
                              read_privacy_policy_checked={this.state.read_privacy_policy_checked}
                              loader={this.state.loader}
                              onSubmitApplication={this.onSubmitApplication}
                           />
                        </div>
                        : <AtsiApplicationSent applicationStatus={{state: this.state.applicationStatus}} jobseeker_details={{ given_name: this.state.given_names, family_name: this.state.family_name }} position_details={this.state.position_details} />}
                  </div>}
         </div>
      );
   }
}