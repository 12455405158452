import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import HiupApi from "../../Sources/HiupApi";
import "./Modal.css"
import {Link} from "react-router-dom";



class Login extends Component {
    constructor() {
        super()
        this.state = {
           email_address:"",
           password: "",
           loader: false,
           error_status:false,
           error_message:""
         }
    }

   onChange = (event)=> {
        this.setState({[event.target.name]: event.target.value})
      }
    
   onSubmitSignIn = (event) => {

     const self = this;

     event.preventDefault()

     self.setState({loader: true})

     self.jobseeker_login_schema = {
      "email": this.state.email_address,
      "password": this.state.password
      }
      
      if(self.state.email_address.length > 0 || self.state.password.length > 0){
         let api = new HiupApi();
         api.login(self.jobseeker_login_schema).then(function(response) {
            self.props.history.push(`/position/${self.props.position_id}/application`)
         }).catch(errorMessage => {
         if(errorMessage.data){
            self.setState({loader:false,error_status:true,error_message:errorMessage.data.error_description})
         } else {
            self.setState({loader:false,error_status:true,error_message:"something went wrong"})
         }
         
         setTimeout(() => { self.setState({ error_status: false }) }, 5000);
         })
      } else {
         this.setState({ error_status: true, error_message:"email and password required",loader:false})
         setTimeout(() => { self.setState({ error_status: false }) }, 5000);
      }
      
   }

      render() {

          return (
             <div>
               <div className={this.props.toggle_login_modal ? "modal  display-block" : "modal display-none"} tabIndex="-1" role="dialog" aria-labelledby="sign-inLabel" aria-hidden="true" >
                   <div className="modal-dialog modal-custom" role="document"  style={{top: "150px"}}>
                      <div className="modal-content">
                         <div className="modal-header" onClick={this.props.onCloseSignInModal}>
                            <h5 className="modal-title" id="sign-inLabel"><img src="/imgs/logo.png" alt="premier-logo" /></h5>
                            <button type="button" className="close" style={{color: "#fff"}}>
                                 <span aria-hidden="true">&times;</span>
                            </button>
                         </div>
                         <div className="modal-body">
                            <h6>Sign in / Register to apply for job</h6>
                            <form>
                               <div className="form-group input-group-prepend">
                                  <span className="input-group-text"><i className="fa fa-envelope"></i></span>
                                  <input type="text" className="form-control user-name" placeholder="email" name="email_address" onChange={this.onChange} />
                               </div>
                               <div className="form-group input-group-prepend">
                                  <span className="input-group-text"><i className="fa fa-lock" style={{fontSize:"24px"}}></i></span>
                                  <input type="password" className="form-control" placeholder="password" name="password" onChange={this.onChange} />
                               </div>
                               {this.state.error_status === true ? <span className="text-danger">{this.state.error_message}</span>:null}
                               <div className="modal-footer text-center" >
                                  <button type="submit" className="btn-register" style={{fontWeight:"600"}} onClick={this.onSubmitSignIn}>{this.state.loader ?<i className="fa fa-spinner fa-spin" />:null} Sign In</button>
                                  <p style={{fontSize:"12px"}}><strong>Don't have an account? <Link to="#" className="btn-sign-in" onClick={this.props.onToggleRegisterModal}>Register</Link></strong></p>
                               </div>
                            </form>
                         </div>
                      </div>
                   </div>
               </div>
            </div>
          );
      }
}


export default withRouter(Login);