import React, { Component } from "react";

export default class AtsiApplicationSent extends Component {

   constructor(props) {
      super(props)
      this.state = {
         mobile_country_code: '',
         mobile_number: '',
         error_status: false,
         success_status: false,
         application_status: 'COMPLETE',
         error_message: 'NOT OK',
         success_message: 'OKKKKK'
      }
   }

   getMobileInput = (event) => {
      this.setState({ [event.target.name]: event.target.value });
   }

   render() {
      return (
         <div>
            <div className="body-wrap-in">
               <div className="row">
                  <div id="main" className="col-md-9 padding-right60">
                     <div className="header-info primary-border-bottom padding-bottom-30 margin-bottom-30" style={{ borderColor: "#D8D8D8" }}>
                        {
                           <div>
                              <div className="title">
                                 <h2>Application Status</h2>
                                 <p>{this.props.jobseeker_details.given_name} {this.props.jobseeker_details.family_name}</p>
                              </div>
                              <address className="clearfix">
                                 <span className="location-info">
                                    <p>{this.props.position_details.job_title}</p>
                                    <p>{this.props.position_details.target_company_location}</p>
                                 </span>
                              </address>
                           </div>
                        }
                     </div>
                     <section id="job_purpose" className="col-md-9 padding-bottom-30 margin-bottom-30">
                        <article>
                           {/* <div className="title title-getwork" >
                              <h3>Congratulations</h3>
                              <h4>Get Work. Get Skills. Get Paid.</h4>
                           </div> */}
                           <img className="d-none" alt="atsi_track" src={"https://www.at-ats.com/tracker/status?atsId=HIUPAPP&status=" + this.props.applicationStatus.state === "OK" ? "COMPLETE" : "JOB_EXPIRED"} />
                           {this.props.applicationStatus.state === "OK" ? <span><h3 className="title title-getwork" >Congratulations Application Sent</h3> </span> : null}
                           {this.props.applicationStatus.state === "ERROR" ? <span> <h3 className="title title-getwork">JOB_EXPIRED</h3></span> : null}
                        </article>
                     </section>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}