import React, { Component } from "react";
import validator from "validator"
import HiupApi from "../../Sources/HiupApi";
import Register from "../Modal/Register";
import Authentication from "../../Authentication/Authentication";
import { animateScroll as scroll } from "react-scroll";
import PreLoader from '../PreLoader/PreLoader';
import JobSpecification from './JobSpecification';
import RecruiterDetails from './RecruiterDetails';
import StickySideBar from './StickySideBar';
import Footer from './Footer';
import Description from './Description';
import JobDetail from './JobDetail';
import RequiredAssetList from './RequiredAssetList';

export default class JobPositionDetail extends Component {

  constructor(props){
    super(props)

    this.state= {
      position_details: {},
      is_loading: true,
      is_published: true,
      toggle_register_modal: false
    }

  }

  componentDidMount() {
    const self = this;
    let isValid = validator.isUUID(this.props.match.params.position_id)
    if(isValid === false) {
      this.setState({ is_published: false})
    } else {
      self.getPositionDetails();
    }
  }

  scrollToTop = () => {
   scroll.scrollToTop();
 };


  getPositionDetails() {
    const self = this;

    let api = new HiupApi();
    api.getPosition(self.props.match.params.position_id)
      .then((position_details) => {
   
        if(position_details.job_id){
           
           let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
           position_details.start_date = new Date(position_details.start_date).toLocaleDateString('en-UK',options);
           position_details.closing_date = new Date(position_details.closing_date).toLocaleDateString('en-UK',options);
          this.setState({position_details: position_details, is_loading:false})
        }
      })
      .catch((errorMessage) => {
        this.setState({is_published: false})
      });
  }

  onToggleRegisterModal = () => {
     if(Authentication.isAuthenticated()){
      this.props.history.push(`${this.props.match.params.position_id}/application`);
     }else{
      this.setState({ toggle_register_modal : !this.state.toggle_register_modal})
     }
   
 }

  render() {

   let RequiredDocuments;
   let RequiredCertificates;
   let OptionalDocuments;

   const job_position_details = this.state.position_details
   const required_wallet_assets = this.state.position_details.wallet_assets;
   
   
   if(required_wallet_assets){
      RequiredDocuments = required_wallet_assets.filter(asset => {
         return asset.asset_type === "document" && asset.is_required === true
      }).map((filterdata,i) => {
         return (<li key={i}>{filterdata.asset_title}</li>)
      })
            
   }
 
   if(required_wallet_assets){
      RequiredCertificates = required_wallet_assets.filter(asset => {
         return asset.asset_type === "certificate" && asset.is_required === true
      }).map((filterdata,i) => {
         return (<li key={i}>{filterdata.asset_title}</li>)
      })
   }
  
   if(required_wallet_assets){
      OptionalDocuments = required_wallet_assets.filter((asset) => {
         return asset.is_required === false
      }).map((filterdata,i) => {
         return (<li key={i}>{filterdata.asset_title}</li>)
      })
   }
   
    return (
       <div>
       {this.state.is_published === false? 
         (<div>
            <h1 className="title loading-bottom">POSITION NOT PUBLISHED</h1>
         </div>)
            :this.state.is_loading === true ? 
            (<PreLoader />) 
            : 
            <div>
               <div className="body-wrap-in">
               <div className="row">
                  <div id="main" className="col-lg-9 col-md-12 col-sm-12 col-xs-12 padding-right60">
                     <JobDetail job_position_details={job_position_details} />
                     <JobSpecification job_position_details={job_position_details}/>
                     <RecruiterDetails job_position_details={job_position_details} />
                     <section id="evidence_required" className={required_wallet_assets === undefined || required_wallet_assets.length <= 0 ? "display-none":"primary-border-bottom padding-bottom-30 margin-bottom-30"} style={{borderColor: "#E60B57"}}>
                        <RequiredAssetList RequiredDocuments={RequiredDocuments} RequiredCertificates={RequiredCertificates} OptionalDocuments={OptionalDocuments}/>
                     </section>
                     <Description job_position_details={job_position_details} />
                  </div>
                  <StickySideBar onToggleRegisterModal={this.onToggleRegisterModal} />
               </div>
               <Footer onToggleRegisterModal={this.onToggleRegisterModal}/>
               </div>
               <Register toggle_register_modal={this.state.toggle_register_modal} onToggleRegisterModal={this.onToggleRegisterModal} position_id={this.props.match.params.position_id} />
            </div>}
         </div>
      );
   }
}