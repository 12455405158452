import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Login from "./SignIn";
import HiupApi from "../../Sources/HiupApi"
import "./Modal.css";
import {Link} from "react-router-dom";
import validate from "../../utils/FormValidation";

class Register extends Component {
  constructor(props){
    super(props)

    this.state={
      title:'Mr.',
      given_names:null,
      family_name:null,
      email_address:null,
      password:null,
      confirm_password:null,
      toggle_login_modal: false,
      loader: false,
      error_status:false,
      error_message:"",
      formErrors: {
         given_names: "",
         family_name: "",
         email_address: "",
         password: ""
       },
       error_user_message : ""
    }

  }

  onSelectValue = (event) => {
    this.setState({title : event.target.value})
  }

  onChange = (event)=> {
    const self = this;
    const { name, value } = event.target;
    self.formValidation(name,value);
    
  }

 onToggleSignInModal = () => {
    this.props.onToggleRegisterModal()
    this.setState({ toggle_login_modal : !this.state.toggle_login_modal})
  
 }

 onCloseSignInModal = () => {
    this.setState({toggle_login_modal:!this.state.toggle_login_modal})
 }

 formValidation = (name,value) => {

  let formErrors = { ...this.state.formErrors }

  switch (name) {
    case "given_names":
      formErrors.given_names =
        value.length < 1 ? "given name required" : "";
      break;
    case "family_name":
      formErrors.family_name =
        value.length < 1 ? "family name required" : "";
      break;
    case "email_address":
      formErrors.email_address = validate.validateEmail(value)
        ? ""
        : "invalid email address";
      break;
    case "password":
      formErrors.password =
      validate.validatePassword(value) ? "":"password must be at least 8 characters and contains one capital letter, one small letter and one numeric character.";
      break;
    default:
      break;
  }

  this.setState({ formErrors, [name]: value });
 }

  onSubmitSignUp = (event) => {

    const self = this;
    event.preventDefault()
    self.setState({loader: true}) 

    if (validate.formValid(self.state)) {
       
      self.jobseeker_register_schema = {
            "title": this.state.title,
            "given_names": this.state.given_names,
            "family_name": this.state.family_name,
            "email_address": this.state.email_address,
            "password": this.state.password,
            "user_role": "jobseeker"
          }
      
          self.jobseeker_login_schema = {
             "email": this.state.email_address,
             "password": this.state.password
          }
      
          if(self.state.password === self.state.confirm_password) {
             let api = new HiupApi();
             api.registerJobseeker(self.jobseeker_register_schema).then(function(response){
        
                if(response.data.user_id){
                  api.login(self.jobseeker_login_schema).then(function(response){
                     self.props.history.push(`/position/${self.props.position_id}/application`);
                  }).catch(errorMessage => {
                    self.setState({loader:false,error_status:true,error_message:errorMessage.data.error_description})
                    setTimeout(() => { self.setState({ error_status: false }) }, 5000);
                   
                 })
                } else {
                  self.setState({loader:false, error_status: true,errorMessage : "Something went wrong"})
                }
             }).catch(errorMessage => {
    
                self.setState({loader:false, error_status: true})
              
                if(!errorMessage.data.user_message || errorMessage.data.message){
                  self.setState({errorMessage : "Something went wrong"});
                }else {
                  if(typeof errorMessage.data.user_message === "string"){
                    self.setState({ error_message: "Something went wrong"});
                  }
                  if(typeof errorMessage.data.user_message.email_address === "object"){
                    self.setState({ error_message: errorMessage.data.user_message.email_address[0]});
                  } else {
                    self.setState({errorMessage : "Something went wrong"});
                  }
                  
                }
                setTimeout(() => { self.setState({ error_status: false }) }, 5000);
                
            });
            
          } else {
            self.setState({loader: false})
          }
      
    } else {
      let formErrors = { ...this.state.formErrors }
      if(!this.state.given_names){
        formErrors.given_names ="given name required."
      }
      if(!this.state.family_name){
        formErrors.family_name = "family name required."
      }
      if(!this.state.email_address){
        formErrors.email_address ="email address required."
      }
      if(!this.state.password){
        formErrors.password ="password required"
      }
      self.setState({formErrors})
      self.setState({loader: false}) 
    }  
  }

  render() {

   const { formErrors } = this.state;
    
    return (
       <div>
         <div className={this.props.toggle_register_modal ? "modal display-block" : "modal display-none"} tabIndex="-1" role="dialog" aria-labelledby="registerLabel" aria-hidden="true" >
             <div className="modal-dialog modal-custom" role="document" style={{top: "80px"}} >
                <div className="modal-content">
                   <div className="modal-header" onClick={this.props.onToggleRegisterModal}>
                      <h5 className="modal-title" id="registerLabel"><img src="/imgs/logo.png" alt="premier-logo" /></h5>
                      <button type="button" className="close" style={{color: "#fff"}}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                   </div>
                   <div className="modal-body">
                      <h6>Sign in / Register to apply for job</h6>
                      <form action="">
                       <div className="form-group">
                          <select value={this.state.title} className="custom-select" id="title" placeholder="title" style={{width:"100px"}} onChange={this.onSelectValue}>
                             <option value="" disabled hidden>title</option>
                             <option value="Mr.">Mr.</option>
                             <option value="Mrs.">Mrs.</option>
                             <option value="Ms.">Ms.</option>
                          </select>
                       </div>
                       <div className="form-group">
                          <input type="text" className={formErrors.given_names.length > 0 ? "form-control is-invalid" : "form-control"} id="gName" placeholder="given name" name="given_names" onChange={this.onChange} />
                          {formErrors.given_names.length > 0  && (
                              <span className="text-danger formvalidation">{formErrors.given_names}</span>
                           )}
                       </div>
                       <div className="form-group">
                          <input type="text" className={formErrors.family_name.length > 0 ? "form-control is-invalid" : "form-control"} id="fName" placeholder="family name" name="family_name" onChange={this.onChange} />
                          {formErrors.family_name.length > 0 && (
                              <span className="text-danger formvalidation">{formErrors.family_name}</span>
                           )}
                       </div>
                       <div className="form-group input-group-prepend">
                          <span className="input-group-text"><i className="fa fa-envelope"></i></span>
                          <input type="email" className={formErrors.email_address.length > 0 ? "form-control is-invalid" : "form-control"} id="email" placeholder="email" name="email_address" onChange={this.onChange} />
                       </div>
                       {formErrors.email_address.length > 0 && (
                              <span className="text-danger formvalidation">{formErrors.email_address}</span>
                           )}
                       <br />
                       <div className="form-group input-group-prepend input-pwd">
                          <span className="input-group-text"><i className="fa fa-lock"></i></span>
                          <input type="password" className={formErrors.password.length > 0 ? "form-control is-invalid" : "form-control"} id="pwd" placeholder="password" name="password" onChange={this.onChange} />
                       </div>
                       {formErrors.password.length > 0 && (
                              <span className="text-danger formvalidation">{formErrors.password}</span>
                           )}
                       <div className="form-group input-group-prepend">
                          <span className="input-group-text"><i className="fa fa-lock"></i></span>
                          <input type="password" className="form-control" id="cpwd" placeholder="confirm password" name="confirm_password" onChange={this.onChange} />
                       </div>
                       {this.state.password === this.state.confirm_password ? null:
                              <span className="text-danger formvalidation">password does not match the confirm password.</span>
                           }
                        {this.state.error_status === true ? <span className="text-danger formvalidation">{this.state.error_message}</span>:null}
                       <div className="modal-footer text-center" >
                          <Link to="#" className="btn-register" style={{fontWeight:"600"}} onClick={this.onSubmitSignUp}>{this.state.loader ?<i className="fa fa-spinner fa-spin" />:null} Register</Link>
                          <p style={{fontSize:"12px"}}><strong>Already have an account? <Link to="#" id="show" className="btn-sign-in" onClick={this.onToggleSignInModal} >Sign In</Link></strong></p>
                          <p style={{fontSize:"12px"}}><strong>By pressing “Register” you agree to the <u>terms & conditions</u></strong></p>
                       </div>
                    </form>
                   </div>
                 </div>
             </div>
         </div>
         <Login toggle_login_modal={this.state.toggle_login_modal} onToggleRegisterModal={this.onToggleSignInModal} position_id={this.props.position_id} onCloseSignInModal={this.onCloseSignInModal} />
      </div>
    );
  };

}

export default withRouter(Register);