import React, { Component } from "react";
import validator from "validator";
import HiupApi from "../../Sources/HiupApi";
import ApplicationSent from "../ApplicationSent/ApplicationSent";
import PreLoader from '../PreLoader/PreLoader';
import ListAssets from '../ListAssets/ListAssets';
import Footer from './Footer';
import JobInformation from './JobInformation';
import AboutYou from './AboutYou';
import CheckRequiredAssetList from './CheckRequiredAssetList';


export default class ApplicationForm extends Component {

   constructor(props){
      super(props) 
         this.state = {
            title:"",
            given_names:"",
            family_name:"",
            email_address:"",
            contact_number:"",
            about_me:"",
            location_value:"",
            position_details: {},
            jobseeker_evidences_asset_id: [],
            is_loading: true,
            is_published : true,
            can_apply_job: false,
            read_privacy_policy_checked : false,
            error_status:false,
            error_message:null,
            loader:false,
            checkbox:false,
            application_sent:false,
            enable_save_button:false,
            response_status_message:"",
            response_status:false,
            statusmessagecolor:''
         }
      }

   componentDidMount() {
      const self = this;

      let isValid = validator.isUUID(self.props.match.params.position_id)
      if(isValid === false) {
        this.setState({ is_published : false})
      } else {
         self.getJobSeekerDetails()
         Promise.all([self.getPositionDetails(),self.getJobSeekerEvidences()])
            .then(result =>{
               self.verifyEvidences(result[0],result[1]);
               self.setState({ is_loading: false })
            }).catch(errorMessage => {
               this.setState({ is_published : false})
            })
      }

   }

   getPositionDetails = () => {

      const self = this;

      let api = new HiupApi();
      return api.getPosition(self.props.match.params.position_id)
        .then((position_details) => {
          if(position_details.job_id){
            this.setState({position_details: position_details})
          }
         let wallet_asset = position_details.wallet_assets.filter(asset => {
            return asset.is_required === true
         }).map(asset => {
            return asset.asset_id;
         })
         return wallet_asset

        }).catch((errorMessage) => {
          this.setState({is_published: false})
        });
   }

   

   getJobSeekerDetails = () => {
      
      const self = this;

      const api = new HiupApi();
      api.getJobseekerInformation().then(function(jobseeker_profile){
         self.setState({
             title: jobseeker_profile.title,
             given_names: jobseeker_profile.given_names,
             family_name: jobseeker_profile.family_name,
             email_address: jobseeker_profile.email_address,
             contact_number: jobseeker_profile.contact_number,
             about_me: jobseeker_profile.about_me,
         })
      }).catch((errorMessage) => {
         this.setState({is_published: false})
       });
   }

   getJobSeekerEvidences = () => {

      const self = this;

      const api = new HiupApi();
      return api.getWalletEvidence().then(function(response){
   
         let jobseeker_evidences_asset_id = response.data.Evidences.map(evidence => {

            return evidence.asset_id
         })
         self.setState({jobseeker_evidences_asset_id : jobseeker_evidences_asset_id})
         return jobseeker_evidences_asset_id
      }).catch((errorMessage) => {
         this.setState({is_published: false})
       });
   }

   onSelectValue = (event) => {
      this.setState({location_value : event.target.value})
   }
   
   handleChangeAboutYou = (event) => {
      event.target.value.length > 0 ? this.setState({about_me:event.target.value, enable_save_button:true})
         :this.setState({about_me:event.target.value, enable_save_button:false});
   }

   handleSubmitAboutYou = (event) => {
      event.preventDefault();
      const self = this;
      
      const { about_me } = self.state;
      const api = new HiupApi();
      api.submitAboutYou(about_me).then(function(submit_response){
         self.setState({response_status_message:submit_response.data.user_message,response_status:true,statusmessagecolor:"text-success",enable_save_button:false});
         api.getJobseekerProfile().then(function(response){
            setTimeout(() => {
               self.setState({response_status: false});
            }, 2000);
         })
      }).catch(errorMessage => {
         console.log(errorMessage);
      })



   }

   onTogglePrivacyPolicyButton = () => {
      
      this.setState({ 
         read_privacy_policy_checked : !this.state.read_privacy_policy_checked,
         checkbox:!this.state.checkbox
         })
   }

   verifyEvidences = (required_wallet_asset,jobseeker_evidence) => {

      const self = this;

      let isValid = required_wallet_asset.every(function(value){ return jobseeker_evidence.indexOf(value) >= 0})
      if(isValid){
         self.setState({ can_apply_job : true})
      }
   }

   createApplication = () => {

      const self = this;
      const api = new HiupApi();
      return api.createApplication(self.props.match.params.position_id).then(function(response){
            self.setState({loader:false})
            if(response.data.application_id){
               return response.data.application_id
            }
            if (response.data.Header && response.data.Header.Status === 'ERROR') {
               self.setState({error_status: true, error_message: response.data.Header.ErrorMsg === "DUPLICATED_REQUEST" ? "Duplicate Request": "Technical Error"})
            }
            
         }).catch(errorMessage => {
         
            self.setState({loader:false})
            if(errorMessage.data){
               self.setState({error_status: true, error_message: errorMessage.data.user_message})
            }else{
               self.setState({error_status: true, error_message:"something went wrong"})
            }
      })
   }

   sendApplication = (application_id) => {

      const self = this;
      const api = new HiupApi();
      return api.sendApplication(application_id).then(function(response) {
            return response
         }).catch(errorMessage => {
         
            self.setState({loader:false})
            if(errorMessage.data){
               self.setState({error_status: true, error_message: errorMessage.data.user_message})
            }else{
               self.setState({error_status: true, error_message:"something went wrong"})
            }
         })
   }

   onSubmitApplication = (event) => {

      const self = this;

      event.preventDefault();
      this.setState({loader:true})
      self.createApplication().then(function(application_id){
         if(application_id){
            self.sendApplication(application_id).then(function(response){
               self.setState({loader:false, application_sent: true})
            })
         }
         
      })
   }

  render() {
      let RequiredDocuments;
      let RequiredCertificates;
      let OptionalDocuments;
   
      const job_position_details = this.state.position_details;
      const required_wallet_assets = this.state.position_details.wallet_assets;

      if(required_wallet_assets){
         RequiredDocuments = required_wallet_assets.filter(asset => {
            return asset.asset_type === "document" && asset.is_required === true
         }).map((asset,i) => {
            return (
               <ListAssets
                  key={i}
                  asset={asset}
                  i={i}
                  jobseeker_evidences_asset_id={this.state.jobseeker_evidences_asset_id}
                  inposession='hasdocument'
                  donothave='donothavedocument'
                  asset_type='documents'
                  />
               )
           });
      }
      
      if(required_wallet_assets){
         RequiredCertificates = required_wallet_assets.filter(asset => {
            return asset.asset_type === "certificate" && asset.is_required === true
         }).map((asset,i) => {
            return (
               <ListAssets
                  key={i}
                  asset={asset}
                  i={i}
                  jobseeker_evidences_asset_id={this.state.jobseeker_evidences_asset_id}
                  inposession='hascertificate'
                  donothave='donothavecertificate'
                  asset_type='certificates'
                  />
            )
           });
      }
      if(required_wallet_assets){
         OptionalDocuments = required_wallet_assets.filter(asset => {
            return asset.is_required === false
            }).map((asset,i) => {
               return (
                  <ListAssets
                     key={i}
                     asset={asset}
                     i={i}
                     jobseeker_evidences_asset_id={this.state.jobseeker_evidences_asset_id}
                     inposession='hasoptional'
                     donothave='donothaveoptional'
                     asset_type='other'
                  />
               )
           });
      }
    return (
       <div>
         {this.state.is_published === false? 
            (<div>
               <h1 className="title loading-bottom">POSITION NOT PUBLISHED</h1>
            </div>):
         this.state.is_loading === true ?(<PreLoader />):
            <div>
               {this.state.application_sent === false ?
                  <div className="body-wrap-in">
                     <div className="row">
                        <div id="main" className="col-lg-9 col-md-12 col-sm-12 col-xs-12 padding-right60">
                           <JobInformation job_position_details={job_position_details} />
                           <section id="job_purpose" className="padding-bottom-30 margin-bottom-30">
                              <article>
                                 {/* <div className="title">
                                 <h5><strong>Application</strong></h5>
                                 </div>
                                 <br /> */}
                                 <form action="">
                                    {/* <div className="row"> */}
                                       {/* <div className="col-md-8">
                                          <div className="form-group" style={{width: "100px"}}>
                                             <input type="text" className="form-control" id="title" value={this.state.title ? this.state.title : ""} placeholder="title" name="title" readOnly />
                                          </div>
                                          <div className="form-group">
                                             <input type="text" className="form-control" id="gName" value={this.state.given_names ? this.state.given_names : ""} placeholder="given name" name="given_names" readOnly />
                                          </div>
                                          <div className="form-group">
                                             <input type="text" className="form-control" id="fName" value={this.state.family_name ? this.state.family_name : ""} placeholder="family name" name="family_name" readOnly />
                                          </div>
                                          <div className="form-group input-group-prepend">
                                             <span className="input-group-text"><i className="fa fa-envelope"></i></span>
                                             <input type="email" className="form-control" id="email" value={this.state.email_address ? this.state.email_address : ""} placeholder="enail address" name="email_address" readOnly />
                                          </div>
                                          <div className="form-group input-group-prepend">
                                             <span className="input-group-text"><i className="fa fa-phone"></i></span>
                                             <input type="number" className="form-control" id="phone" value={this.state.contact_number ? this.state.contact_number : ""} placeholder="contact number" name="contact_number" readOnly />
                                          </div>
                                       </div> */}
                                    {/* </div> */}
                                    {/* <br />
                                    <span className="divider"></span>
                                    <br />  */}
                                    <AboutYou 
                                       about_me={this.state.about_me}
                                       response_status={this.state.response_status}
                                       statusmessagecolor={this.state.statusmessagecolor}
                                       response_status_message={this.state.response_status_message}
                                       enable_save_button={this.state.enable_save_button}
                                       handleChangeAboutYou={this.handleChangeAboutYou}
                                       handleSubmitAboutYou={this.handleSubmitAboutYou}
                                    />
                                    <br /> 
                                    {/* <div className="form-group">
                                       <label>Do you have the right to work in the location of this role:</label>
                                       <select name="locationRole" value={this.state.location_value} className="custom-select location-role selectbutton"  onChange={this.onSelectValue}>
                                          <option value="" disabled hidden>Please Select</option>
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                       </select>
                                    </div>
                                    <br />  */}
                                    <span className={required_wallet_assets === undefined || required_wallet_assets.length <= 0 ? null:"divider"}></span>
                                    <br />
                                    <CheckRequiredAssetList  RequiredDocuments={RequiredDocuments} RequiredCertificates={RequiredCertificates} OptionalDocuments={OptionalDocuments} />
                                 </form>
                              </article>
                           </section>
                        </div>
                     </div>
                     <span className="divider"></span>
                     <br />
                     <Footer 
                        can_apply_job={this.state.can_apply_job}
                        checkbox={this.state.checkbox}
                        onTogglePrivacyPolicyButton={this.onTogglePrivacyPolicyButton}
                        error_status={this.state.error_status}
                        error_message={this.state.error_message}
                        read_privacy_policy_checked={this.state.read_privacy_policy_checked}
                        loader={this.state.loader}
                        onSubmitApplication={this.onSubmitApplication}
                         />
                  </div>
               : <ApplicationSent jobseeker_details={{given_name:this.state.given_names,family_name:this.state.family_name}} position_details={this.state.position_details}/>}
            </div>}
         </div>
    );
  }
}