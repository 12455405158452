import React from "react";
import { Link } from "react-scroll";

const StickySideBar = (props) => {
    return (
        <aside id="sticky-sidebar" className="right-sidebar col-md-3 text-center">
            <div className="sticky-top">
               <div className="btn-group btn-fullwidth" role="group" aria-label="">
                  <button type="button" className="btn btn-warning" onClick={props.onToggleRegisterModal}>
                  Apply Now
                  </button>
                  {/* <button type="button" className="btn btn-light" disabled>Share Job</button> */}
               </div>
               <div className="social-group margin-top-30 margin-bottom-30 clearfix">
                  {/* <a href="https://www.facebook.com"><i className="fa fa-facebook"></i></a>
                  <a href="https://twitter.com"><i className="fa fa-twitter"></i></a>
                  <a href="https://www.linkedin.com"><i className="fa fa-linkedin"></i></a> */}
               </div>
               <ul className="main-links padding-none">
                  <li className="title-font"><Link to="job_purpose" spy={true} smooth={true} offset={-100} duration={500}>Job Specifications</Link></li>
                  <li className="title-font"><Link to="main_responsibilities" spy={true} smooth={true} offset={-90} duration={500}>Recruiter Information</Link></li>
                  <li className="title-font"><Link to="evidence_required" spy={true} smooth={true} offset={-90} duration={500}>Evidence Required</Link></li>
                  <li className="title-font"><Link to="person_specification" spy={true} smooth={true} offset={-80} duration={500}>Job Description</Link></li>
               </ul>
            </div>
     </aside>
    );
}

export default StickySideBar;