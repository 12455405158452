import React from "react";

const RecruiterDetails = (props) => {
    return (
        <section id="main_responsibilities" className="primary-border-bottom padding-bottom-30 margin-bottom-30" style={{borderColor: "#92D04F"}}>
        <article>
           <div className="title">
              <h5><strong>Recruiter</strong></h5>
           </div>
           <p>Company Name : {props.job_position_details.recruiter_company_name}</p>
           <p>Company Location : {props.job_position_details.recruiter_company_location}</p>
        </article>   
     </section>
    );
}

export default RecruiterDetails;