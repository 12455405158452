import React, { Component } from "react";
import { Link } from "react-router-dom";
import Authentication from "../../Authentication/Authentication";


class NavBar extends Component {

   onLogout = () => {
      Authentication.logout();
   }

  render() {
    return (
      <header>
         <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark-light padding-none">
            <div className="container">
               <Link to="#" className="navbar-brand"><img src="/imgs/premier-logo.png" alt="premier-logo" /></Link>
               <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
               </button>
               <div className="collapse navbar-collapse" id="navbarCollapse">
                  <ul className="navbar-nav mr-auto text-uppercase pull-right">
                     <li className="nav-item">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.premier-education.com/holidaycamps" className="nav-link">Holiday Camps <span className="sr-only"></span></a>
                     </li>
                     <li className="nav-item">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.premier-education.com/parents" className="nav-link">Parents</a>
                     </li>
                     <li className="nav-item">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.premier-education.com/schools" className="nav-link">Schools</a>
                     </li>
                     <li className="nav-item">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.premier-education.com/coaching" className="nav-link">Coaching</a>
                     </li>
                     <li className="nav-item">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.premier-education.com/franchising" className="nav-link">Franchising</a>
                     </li>
                     {/* <li className="nav-item">
                        <a target="_blank" rel="noopener noreferrer" href="https://makesportyourbusiness.com/?__hstc=191108917.7126f453273bfe1ff05c73ba124548ec.1542349113798.1542349113798.1548847601935.2&__hssc=191108917.7.1548847601935&__hsfp=3478099814&_ga=2.90584858.1258025743.1548847597-2070225136.1542349104" className="nav-link">Careers</a>
                     </li> */}
                     {/* <li className="nav-item">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.premier-education.com/our-values" className="nav-link">Our Values</a>
                     </li>
                     <li className="nav-item">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.premier-education.com/partnerships" className="nav-link">Partnerships</a>
                     </li> */}
                     {Authentication.isAuthenticated() ? 
                     <li className="nav-item">
                        <Link to="#" className="nav-link logoutresponsive" onClick={this.onLogout} data-toggle="tooltip" data-placement="bottom" title="LOGOUT"><i className="fa fa-power-off fa-1x"></i></Link>
                     </li> : ""}
                     
                     
                  </ul>
               </div>
              </div>
         </nav>
      </header>
    );
  }
}

export default NavBar;