import React, { Component } from "react";
import PhoneNumberForm from "../PhoneNumberForm/PhoneNumberForm";
import HiupApi from "../../Sources/HiupApi";
import validate from "../../utils/FormValidation";

export default class ApplicationSent extends Component { 

   constructor(props) {
      super(props)
      this.state = {
         mobile_country_code : '',
         mobile_number : '',
         error_status:false,
         success_status:false,
         error_message:'',
         success_message:''
      }
   }

   getMobileInput = (event) => {
      this.setState({[event.target.name]:event.target.value});
   }

   submitMobileNumber = (event) => {
      event.preventDefault();

      const self = this;
      const {mobile_country_code,mobile_number} = this.state;

      self.jobseeker_mobile_number = '+' + mobile_country_code + mobile_number

      let isValid = validate.validateMobileNumber(self.jobseeker_mobile_number);

      if(isValid){
         self.phonenumberSchema = {
            "mobile_country_code": mobile_country_code,
            "mobile_number": mobile_number
         }
         const api = new HiupApi();
         api.sendMobileNumber(self.phonenumberSchema).then(function(response){
            self.setState({success_status:true,success_message:response.data.user_message,error_status:false});
         }).catch(errorMessage => {
            self.setState({error_status: true, error_message: errorMessage.data.user_message.mobile_number[0],success_status:false});
         })
      }else {
         self.setState({error_status: true, error_message: "Invalid Mobile Number.",success_status:false});
      }
      
   }

   render () {
      return (
         <div>
          <div className="body-wrap-in apllication-sent">
              <div className="row">
                 <div id="main" className="col-md-9 padding-right60">
                    <div className="header-info primary-border-bottom padding-bottom-30 margin-bottom-30" style={{borderColor: "#D8D8D8"}}>
                     {
                        Object.keys(this.props).length <= 0 ?
                        <p>Sorry, you are missing the required documents and certification to apply for this position. In order to update your documents and certification, please download our mobile application.</p>
                        :<div>
                       <div className="title">
                          <h2>Application Sent</h2>
                          <p>{this.props.jobseeker_details.given_name} {this.props.jobseeker_details.family_name}</p>
                       </div>
                       <address className="clearfix">
                          <span className="location-info">
                             <p>{this.props.position_details.job_title}</p>
                             <p>{this.props.position_details.target_company_location}</p>
                          </span>
                       </address>
                    </div>
                     }
                     </div>
                    <section id="job_purpose" className="col-md-9 padding-bottom-30 margin-bottom-30">
                       <article>
                          <div className="title title-getwork">
                             <h3>Get Work. Get Skills. <br/>Get Paid.</h3>
                             <h4>Track your application and see other jobs with Premier Education using the HiUp app.</h4>
                          </div>
                          {this.state.success_status === true ? <span className="text-success">{this.state.success_message}</span>:null}
                          {this.state.error_status === true ? <span className="text-danger">{this.state.error_message}</span>:null}
                          <div id="get-the-app" className="">
                             <PhoneNumberForm getMobileInput={this.getMobileInput} submitMobileNumber={this.submitMobileNumber} />
                          </div>
                          <p className="read-more">The app that gives you access to thousands of jobs and courses, anytime, anywhere.</p>
                       </article>
                       <div className="download-app">
                          <span><a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_ITUNES_LINK}><img src="/imgs/appstore.png" alt="" height="50" width="167" /></a></span>
                          <span><a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_GOOGLE_PLAYSTORE_LINK}><img src="/imgs/googleplay.png" alt=""  height="47" width="175" style={{paddingLeft:"15px"}}/></a></span>
                       </div>
                    </section>
                 </div>
              </div>
          </div>
       </div>
      );
      }
   }