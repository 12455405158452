import React from 'react';                            


const PhoneNumberForm = (props) => {

   return (
       <form className="form-inline">
         <div className="form-group country-code-form-group">
            <label className="sr-only" htmlFor="get-the-app-telephone">Mobile Number</label>
            <div className="input-group input-group-number">
               <div className="input-group-addon">+</div>
               <input type="text" className="form-control" id="get-the-app-country-code" name="mobile_country_code" placeholder="44" onChange={props.getMobileInput} />
            </div>
         </div>
         <div className="form-group telephone-number">
            <div className="input-group">
               <input type="text" className="form-control" id="get-the-app-telephone" name="mobile_number" placeholder="Phone Number" onChange={props.getMobileInput} />
            </div>
         </div>
         <button className="btn btn-sm btn-get-the-app" id="get-the-app-btn" onClick={props.submitMobileNumber}>GET THE APP</button>
       </form>
   );
                            
}

export default PhoneNumberForm;
