import React from "react";
import ApplicationSent from '../ApplicationSent/ApplicationSent';

const Footer = (props) => {
    return (
        <footer id="footer">
        {props.can_apply_job === true ? 
        <div>
           <p><strong>Candidate Data Privacy *</strong></p>
           <p>By submitting your application you are agreeing to Premier Education Limited collecting, storing and processing your personal data in accordance with our Candidate Privacy Policy. We encourage you to read through our Candidate Privacy Policy to understand more about how and why we collect your personal data, the type of information we collect about you, how we use and share your personal data, how long we store your personal data and to learn more about your choices and rights.</p>
           <div className="row">
              <div className="col-md-6 custom-control custom-radio">
                 <input type="checkbox" value={props.checkbox} className="custom-control-input"  id="submitapplication" name="privacypolicy" onChange={props.onTogglePrivacyPolicyButton} />
                 <label className="custom-control-label" htmlFor="submitapplication"> I've read and understood the above</label>
           </div>
           </div>
           <br />
           {props.error_status === true ? <span className="text-danger">{props.error_message}</span>:null}
           <br />
           <div className="btn-group btn-group-md d-inline-block" disabled role="group" aria-label="">
              <button type="button"  className={props.read_privacy_policy_checked === true ? "btn btn-warning d-inline-block":"btn btn-warning d-inline-block disabled"} disabled={!props.checkbox} onClick={props.onSubmitApplication}>{props.loader ?<i className="fa fa-spinner fa-spin" />:null} Submit Application</button>
           </div>
        </div>:
        <article >
              <ApplicationSent />
         </article>
        }       
      </footer>
    );
}

export default Footer;