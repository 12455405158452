import React from "react";

const Footer = (props) => {
    return (
        <footer id="footer">
            <div className="btn-group btn-group-md d-inline-block" role="group" aria-label="">
               <button type="button" className="btn btn-warning d-inline-block" onClick={props.onToggleRegisterModal}>Apply Now</button>
               {/* <button type="button" className="btn btn-light d-inline-block" disabled>Share Job</button> */}
            </div>
            {/* <div className="social-group d-inline-block margin-top-30 margin-bottom-30 clearfix">
               <a href="https://www.facebook.com"><i className="fa fa-facebook"></i></a>
               <a href="https://twitter.com"><i className="fa fa-twitter"></i></a>
               <a href="https://www.linkedin.com"><i className="fa fa-linkedin"></i></a>
            </div> */}
        </footer>
    );
}

export default Footer;