import React from "react";
import "./PreLoader.css"

const PreLoader = () => {
    return (
        <div className="preloader preloader_full-screen">
        <div className="circle circle_green"></div>
        <div className="circle circle_red"></div>
      </div>
    );
}

export default PreLoader;