import React from "react";
import DOMPurify from "dompurify";

const Description = (props) => {
    return (
        <section id="person_specification" className="primary-border-bottom padding-bottom-30 margin-bottom-30" style={{borderColor: "#94025F"}}>
            <article>
               <h5><strong>Description</strong></h5>
               <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.job_position_details.description) }} />
            </article>
        </section>
    );
}

export default Description;