import React, { Component } from 'react';
import Routes from "./Routes/Routes";
import Container from './Components/Container/Container';

class App extends Component {
  render() {
    return (
        <Container>
          <Routes />
        </Container>
    );
  }
}

export default App;
