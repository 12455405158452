import React from "react";
import { Link } from "react-router-dom";

const JobInformation = (props) => {
    return (
        <div className="header-info primary-border-bottom padding-bottom-30 margin-bottom-30" style={{borderColor: "#D8D8D8"}}>
            <div className="title">
               <p className="title-font">Job Title</p>
               <h2>{props.job_position_details.job_title}</h2>
            </div>
            <address className="clearfix">
               <span className="location-info">
               <strong>{props.job_position_details.target_company_location}</strong>
               <Link to={`/position/${props.job_position_details.job_id}`}><strong className="see-job">See Job</strong></Link>
               </span>            
            </address>
     </div>
    );
}

export default JobInformation;