import axios from 'axios'

class HiupApi {

    constructor(base_url) {
        this.base_url = 'https://apps.hiupservices.co.uk/iia'
    }

    parseErr(err) {

        let error_message = ""

        if (typeof err.user_message === 'string') {
            error_message += err.user_message
        } else if (typeof err.user_message === 'object') {

            for (let errorKey in err.user_message) {
                if (err.user_message.hasOwnProperty(errorKey)) {
                    error_message += err.user_message[errorKey][0] + "\n"
                }
            }
        }
        return error_message
    }

    getPosition(position_id) {
    
        const self = this

        return new Promise(function (resolve, reject) {
            axios({
                method: 'get',
                url: self.base_url + '/jobs/' + position_id,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'client_type': 'web'
                }
            }).then((res) => {
                resolve(res.data)
            }).catch((error) => {
                if (error.response)
                    reject(self.parseErr(error.response.data))
                else
                    reject(error.message)

            })
        });
    }

    login(jobseeker_login_schema) {
        const self = this;

        return new Promise(function (resolve, reject) {
            axios({
                method: 'post',
                url: self.base_url + '/login',
                data: {username: jobseeker_login_schema.email, password: jobseeker_login_schema.password},
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Hiup-Region': 'US'
                }
            }).then((res) => {
                localStorage.setItem("id_token",res.data.id_token)
                self.getJobseekerProfile().then(response => {
                    resolve(response)
                })

            }).catch((error) => {
                if(error.response) {
                    let err = {
                        data: error.response.data,
                        statusCode: error.response.status,
                        headers: error.response.headers
                    }
                    reject(err);
                } else  {
                    let err = {
                        response: {error_description: "something went wrong"}
                    }
                    reject(err);
                }
            })
        })
    }

    getToken() {
        return localStorage.getItem('id_token')
    }

    getJobseekerId() {
        let profile = localStorage.getItem('profile');
        profile = JSON.parse(profile);
        let jobseeker_id = profile.user_id;
        return jobseeker_id;
    }

    getJobseekerInformation() {

        return new Promise(function (resolve, reject){

            let profile = localStorage.getItem('profile');
            if(profile){
                profile = JSON.parse(profile);
                resolve(profile)
            }else {
                return (null)
            }
            
        })
        
    }

    registerJobseeker(jobseeker_register_schema) {

        const self = this;

        return new Promise(function (resolve, reject){
            axios({
                method: 'post',
                url: self.base_url + '/profile/jobseeker/add',
                data: jobseeker_register_schema,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Hiup-Region': 'US'
                }
            }).then((res) => {
                resolve(res);
            }).catch((error) => {
                if(error.response) {
                    let err = {
                        data: error.response.data,
                        statusCode: error.response.status,
                        headers: error.response.headers
                    }
                    reject(err);
                } else  {
                    let err = {
                        response: {error_description: "something went wrong"}
                    }
                    reject(err);
                }
            })
        })
    }

    getJobseekerProfile() {

        const self = this;

        return new Promise(function (resolve, reject){
            axios({
                method: 'get',
                url: self.base_url + '/profile/jobseeker',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': self.getToken(),
                    'Hiup-Region': 'US'
                }
            }).then((res) => {
                localStorage.setItem("profile", JSON.stringify(res.data.jobseeker));
                resolve(res);

            }).catch((error) => {
                if(error.response) {
                    let err = {
                        data: error.response.data,
                        statusCode: error.response.status,
                        headers: error.response.headers
                    }
                    reject(err);
                } else  {
                    let err = {
                        response: {error_description: "something went wrong"}
                    }
                    reject(err);
                }
            })
        })
    }

    getWalletEvidence() {

        const self = this;

        const wallet_evidence_schema = {
            "creator_id": self.getJobseekerId()
        }

        return new Promise(function (resolve, reject){
            axios({
                method: 'post',
                url: self.base_url + '/wallet/evidence/search',
                data: wallet_evidence_schema,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : self.getToken(),
                    'Hiup-Region': 'US'
                }
            }).then((res) => {
                resolve(res);
            }).catch((error) => {
                if(error.response) {
                    let err = {
                        data: error.response.data,
                        statusCode: error.response.status,
                        headers: error.response.headers
                    }
                    reject(err);
                } else  {
                    let err = {
                        response: {error_description: "something went wrong"}
                    }
                    reject(err);
                }
            })
        })
    }

    createApplication(job_id,user_id) {

        const self = this;

        let jobseeker_application_schema = {
            "job_id": job_id
        }

        return new Promise(function (resolve, reject){
            axios({
                method: 'post',
                url: self.base_url + '/jobs/job-apply-web',
                data: jobseeker_application_schema,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : self.getToken(),
                }
            }).then((res) => {
                resolve(res);
                
            }).catch((error) => {
                if(error.response) {
                    let err = {
                        data: error.response.data,
                        statusCode: error.response.status,
                        headers: error.response.headers
                    }
                    reject(err);
                } else  {
                    let err = {
                        response: {error_description: "something went wrong"}
                    }
                    reject(err);
                }
            })
        })
    }

    sendApplication(application_id) {

        const self = this;

        let jobseeker_application_schema = {
            "application_id": application_id,
            "progress": "application_sent"
        }
        
        return new Promise(function (resolve, reject){
            axios({
                method: 'post',
                url: self.base_url + '/applications',
                data: jobseeker_application_schema,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : self.getToken(),
                    'Hiup-Region': 'US'
                }
            }).then((res) => {
                console.log("sendappppppppppppppppppppp",res);
                resolve(res);
            }).catch((error) => {
                if(error.response) {
                    let err = {
                        data: error.response.data,
                        statusCode: error.response.status,
                        headers: error.response.headers
                    }
                    reject(err);
                } else  {
                    let err = {
                        response: {error_description: "something went wrong"}
                    }
                    reject(err);
                }
            })
        })
    }

    sendMobileNumber(phonenumberSchema) {

        const self = this;

        return new Promise(function (resolve, reject){
            axios({
                method: 'post',
                url: self.base_url + '/profile/jobseeker/mobile/applink',
                data: phonenumberSchema,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : self.getToken(),
                    'Hiup-Region': 'US'
                }
            }).then((res) => {
                resolve(res);
            }).catch((error) => {
                if(error.response) {
                    let err = {
                        data: error.response.data,
                        statusCode: error.response.status,
                        headers: error.response.headers
                    }
                    reject(err);
                } else  {
                    let err = {
                        response: {error_description: "something went wrong"}
                    }
                    reject(err);
                }
            })
        })
    }

    submitAboutYou(about_you_text) {

        const self = this;

        self.profileSchema = {
            "user_id": self.getJobseekerId(),
            "about_me":about_you_text
        }

        return new Promise(function (resolve, reject){
            axios({
                method: 'post',
                url: self.base_url + '/profile/jobseeker',
                data: self.profileSchema,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : self.getToken(),
                    'Hiup-Region': 'US'
                }
            }).then((res) => {
                resolve(res);
            }).catch((error) => {
                if(error.response) {
                    let err = {
                        data: error.response.data,
                        statusCode: error.response.status,
                        headers: error.response.headers
                    }
                    reject(err);
                } else  {
                    let err = {
                        response: {error_description: "something went wrong"}
                    }
                    reject(err);
                }
            })
        })
    }
}

export default HiupApi