import React from "react";

const Error = () => {
    return (
      <div className="body-wrap-in">
          <div className="title loading-bottom">
            <h1>Sorry, page not found!</h1>
        </div>
      </div>
    );
}

export default Error;