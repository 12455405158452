import jwtDecode from "jwt-decode";

class Authentication {

    logout(){
        localStorage.removeItem("id_token");
        localStorage.removeItem("profile");
        return true;
    }

    isAuthenticated() {
        const id_token = localStorage.getItem('id_token');
        const profile = JSON.parse(localStorage.getItem('profile'));
        if(!id_token && !profile) {
            return false;
        }
        try {
            let decoded = jwtDecode(id_token);
            if(decoded.exp === null || decoded.exp < (Math.round((new Date().getTime())/1000))) {
                 return false
            }
        } catch (e) {
            return false;
        }

        return true
        
    }
}

export default new Authentication();