import React from "react";

const JobDetail = (props) => {
    return (
        <div className="header-info primary-border-bottom padding-bottom-30 margin-bottom-30" style={{borderColor: "#35ABBF"}}>
            <div className="title">
               <p className="title-font">Job Title</p>
               <h2>{props.job_position_details.job_title}</h2>
            </div>
            <address className="clearfix">
               <span className="location-info">
                  <p className="title-font">Location</p>
                  <strong>{props.job_position_details.target_company_location}</strong>
               </span>
               <span className="reports-to">
                  <p className="title-font">Company</p>
                  <strong>{props.job_position_details.target_company_name}</strong>
               </span>
            </address>
        </div>
    );
}

export default JobDetail;