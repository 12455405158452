import React from "react";

const JobSpecification = (props) => {
    return (
        <section id="job_purpose" className="primary-border-bottom padding-bottom-30 margin-bottom-30" style={{borderColor: "#CCD600"}}>
         <article>
            <div className="title">
               <h5><strong>Job Specifications</strong></h5>
            </div>
            <p>Role : {props.job_position_details.role_name}</p>
            <p>Number of Position : {props.job_position_details.number_of_position}</p>
            <p>Hourly Pay : {props.job_position_details.hourly_rate_of_pay ? props.job_position_details.currency + " " + props.job_position_details.hourly_rate_of_pay:"No Pay Information"}</p>
            <p>Salary : {props.job_position_details.salary ? props.job_position_details.currency + " " + props.job_position_details.salary + " " + props.job_position_details.salary_type :"No Salary Information"}</p>
            <p>Duration : {props.job_position_details.duration}</p>
            <p>Industry : {props.job_position_details.industry_name}</p>
            <p>Start Date : {props.job_position_details.start_date}</p>
            <p>Closing Date : {props.job_position_details.closing_date}</p>
         </article>
     </section>
    );
}

export default JobSpecification;