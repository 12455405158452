import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import NotFound from "../Components/Error/NotFound";
import JobPositionDetail from "../Components/JobPositionDetail/JobPositionDetail";
import ApplicationForm from "../Components/ApplicationForm/ApplicationForm";
import AtsiApplicationDetail from "../Components/ApplicationForm/AtsiApplication";
import ThankYou from "../Components/ThankYou/ThankYou";

export default () =>
  <Switch>
    <Route path="/position/:position_id" exact component={JobPositionDetail} />
    <ProtectedRoute path="/position/:position_id/application" exact component={ApplicationForm} />
    <Route path="/position/:position_id/:user_id" exact component={AtsiApplicationDetail} />
    <Route path="/thankyou" exact component={ThankYou} />
    <Route path="*" component={NotFound} />
  </Switch>;



