import React from "react";

const ListAssets = (props) => {
    return (
        <div key={props.i}>
        <br/>
        <p>{props.asset.asset_title}</p>
           {props.jobseeker_evidences_asset_id.includes(props.asset.asset_id) ? 
              <div className="row text-center">
                 <div className="col-md-3 custom-control custom-radio">
                    <input type="radio" className="custom-control-input" id={`${props.inposession}-${props.i}`} name={`${props.asset_type}-${props.i}`} defaultChecked readOnly disabled/>
                    <label className="custom-control-label" htmlFor={`${props.inposession}-${props.i}`}>In Posession</label>
                 </div>
                 <div className="col-md-3 custom-control custom-radio">
                    <input type="radio" className="custom-control-input" id={`${props.donothave}-${props.i}`} name={`${props.asset_type}-${props.i}`} readOnly disabled/>
                    <label className="custom-control-label" htmlFor={`${props.donothave}-${props.i}`}> Do Not Have</label>
                 </div>
              </div>:
              <div className="row text-center">
                 <div className="col-md-3 custom-control custom-radio">
                    <input type="radio" className="custom-control-input" id={`${props.inposession}-${props.i}`} name={`${props.asset_type}-${props.i}`} readOnly disabled/>
                    <label className="custom-control-label" htmlFor={`${props.inposession}-${props.i}`}>In Posession</label>
                 </div>
                 <div className="col-md-3 custom-control custom-radio">
                    <input type="radio" className="custom-control-input" id={`${props.donothave}-${props.i}`} name={`${props.asset_type}-${props.i}`} defaultChecked readOnly disabled/>
                    <label className="custom-control-label" htmlFor={`${props.donothave}-${props.i}`}> Do Not Have</label>
                 </div>
              </div>}
     </div>)
}

export default ListAssets;