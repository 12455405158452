import React from "react";
import NavBar from "../Header/NavBar";


const Container = (props) => {
    return (
      <div>
        <NavBar />
        <div id="wrapper">
          <div className="media fullwidth-banner">
            <figure><img src="/imgs/hero-home.jpg" style={{top:"-40px"}} alt="banner" /></figure>
          </div>
          <section id="body-wrap">
            <div className="container bg-white">
              {props.children}
            </div>
          </section>
        </div>
      </div>
    );
}

export default Container;