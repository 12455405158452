import React from "react";
let steps = [{
    title: "Signing In:",
    items: [
        {
            title: "1. Get your user login from the email we sent you after applying for the job",
            image: "1.png"
        },
        {
            title: "2. Open HiUp App",
            image: "2.png"
        },
        {
            title: "3. Sign in to the HiUp Jobs App",
            image: "3.png"
        }]
}, {
    title: "Method 1:",
    items: [
        {
            title: "1. Click main menu",
            image: "4.png"
        },
        {
            title: "2. Select applications",
            image: "5.png"
        },
        {
            title: "3. Tap document you want to upload & add cover note",
            image: "6.png"
        }]
}, {
    title: "Method 2:",
    items: [
        {
            title: "1. Click Add",
            image: "7.png"
        },
        {
            title: "2. Select the asset you want to add",
            image: "8.png"
        },
        {
            title: "3. Select from Gallery or take a photo",
            image: "9.png"
        },
        {
            title: "4. Take the photo",
            image: "10.png"
        },
        {
            title: "5. Hit Save",
            image: "11.png"
        },
        {
            title: "6. It is added to your documents / Certificates",
            image: "12.png"
        }]
}, {
    title: "Checking applications:",
    items: [
        {
            title: "1. Click Applications",
            image: "13.png"
        },
        {
            title: "2. View your job applications",
            image: "14.png"
        },
        {
            title: "3. See what other documents you need to upload",
            image: "15.png"
        }]
}]
let screens = [];
steps.forEach(elem => {
    screens.push(<div>
        <div className="row,py-3"><p><b>{elem.title}</b></p></div>
        <div className="row">{elem.items.map((item, key) =>
            <div className="col-sm" key={key} style={{ marginBottom: 20 + 'px' }}>
                <div className="card" style={{ width: 18 + 'rem', maxHeight: 350 + 'px' }}>
                    <img className="card-img-top" src={'/imgs/screens/' + item.image} alt="open app" />
                    <div className="card-body">
                        <p className="card-title text-center" style={{ fontSize: 15 + 'px' }}>{item.title}</p>
                        <p className="card-text"></p>
                    </div>
                </div>
            </div>
        )}
        </div>
    </div>)
});


const ThankYou = () => {
    return (
        <div className="container">
            <p className="pt-3">Thank you for applying for a role with Premier Education, and welcome to our automated recruitment journey.</p>
            <p>At Premier Education, we provide opportunities for aspiring coaches to realise their ambition and give back to their communities - positively impacting children's health and wellbeing across the UK. We think that finding a new job should be stress free, so we've made this the easiest application process you've ever seen.</p>
            <p>All you need to do is download the HiUp app (Hire & Upskill) and you'll be able to track your application from your phone with just a few taps. HiUp also provides you with the opportunity to enhance your earning power through courses and certifications designed to complement your application, and help to grow your passion for sport and activity.</p>
            <p>Shortly, you’ll receive an email from us with your login details for the HiUp app. Download the app on iOS or Google Play and free yourself from the usual stress of job applications. Login using the details provided and head to ‘My Applications’ to track your progress toward interview or employment.</p>
            <p>Best of luck!</p>

            <div className="py-5">
                <h4 className="display-5 pb-3">How to upload document to HiUp App</h4>
                {screens}
            </div>
            <div className="py-3" style={{ background: '#3e4650', position: 'absolute', bottom: -8 + 'px', left: 0, width: 100 + '%' }}></div>
        </div>

    );
}

export default ThankYou;