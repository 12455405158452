function validateEmail(email) {
    const email_regex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return email_regex.test(email);
  }

function validatePassword(password) {
    const password_regex = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    return password_regex.test(password);
}

function validateMobileNumber(mobile_number) {
  const mobile_number_pattern = /^\+[1-9]{1}[0-9]{3,14}$/;
  return mobile_number_pattern.test(mobile_number);
}

function formValid({ formErrors, ...rest }) {
    let valid = true;
  
    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
      val.length > 0 && (valid = false);
    });
  
    // validate the form was filled out
    Object.values(rest).forEach(val => {
      val === null && (valid = false);
    });
  
    return valid;
  };

export default {
    validateEmail,
    validatePassword,
    formValid,
    validateMobileNumber
}
