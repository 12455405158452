import React from "react";

const RequiredAssetList = (props) => {
    return (
        <article>
            {props.RequiredDocuments === undefined || props.RequiredDocuments.length <= 0  ? null:
               <div>
                  <h5><strong>Required Documents</strong></h5>
                  <div className="list-group evidence-required">
                     <ul>
                        {props.RequiredDocuments}
                     </ul>
                  </div>
               </div>}
            {props.RequiredCertificates === undefined || props.RequiredCertificates.length <= 0 ? null:
               <div>
                  <h5><strong>Required Certification</strong></h5>
                  <div className="list-group evidence-required">
                     <ul>
                        {props.RequiredCertificates}
                     </ul>
                  </div>
               </div>}
            {props.OptionalDocuments === undefined || props.OptionalDocuments.length <= 0 ? null:
               <div>
                  <h5><strong>Optional Documents & Certification</strong></h5>
                  <div className="list-group evidence-required">
                     <ul>
                        {props.OptionalDocuments}
                     </ul>
                  </div>
               </div>}
        </article>
    );
}

export default RequiredAssetList;